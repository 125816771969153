import getFocusableElements from "../utils/get-focusable-elements";

class ResponsiveMenu extends HTMLElement {
  constructor() {
    super();

    const self = this;

    this.state = new Proxy(
      {
        status: "open",
        enabled: false
      },
      {
        set(state, key, value) {
          const oldValue = state[key];

          state[key] = value;
          if (oldValue !== value) {
            self.processStateChange();
          }
          return state;
        }
      }
    );
  }

  connectedCallback() {
    this.initialMarkup = this.innerHTML;
    this.render();

    const logo = document.querySelector(".header__logo");

    // Helper vars to ensure mobile nav is triggered at the right instance
    this.hasCollided = undefined;
    this.lastWidth = undefined;

    const observer = new ResizeObserver((observedItems) => {
      const { contentRect } = observedItems[0];
      const windowWidth = window.innerWidth;

      const isColliding = this.isColliding(logo, this.firstElementChild);
      const widthChanged =
        this.lastWidth && this.lastWidth !== contentRect.width;

      if (
        (!widthChanged && isColliding && !this.hasCollided) ||
        windowWidth <= 768
      ) {
        this.hasCollided = true;
        this.lastWidth = contentRect.width;
        this.state.enabled = true;
      } else if (
        widthChanged &&
        this.hasCollided &&
        contentRect.width >= this.lastWidth
      ) {
        this.hasCollided = false;
        this.lastWidth = undefined;
        this.state.enabled = false;
      }
    });

    observer.observe(this.parentNode);
  }

  render() {
    this.innerHTML = `
      <div class="responsive-menu" data-element="responsive-root">
        <button class="responsive-menu__trigger" data-element="responsive-menu-trigger" type="button" aria-label="Open menu">
          <span class="responsive-menu__bar" aria-hidden="true"></span>
        </button>
        <div class="responsive-menu__panel" data-element="responsive-menu-panel">
          ${this.initialMarkup} 
        </div>
      </div>
    `;

    this.postRender();
  }

  postRender() {
    this.trigger = this.querySelector(
      '[data-element="responsive-menu-trigger"]'
    );
    this.panel = this.querySelector('[data-element="responsive-menu-panel"]');
    this.root = this.querySelector('[data-element="responsive-root"]');
    this.focusableElements = getFocusableElements(this);

    if (this.trigger && this.panel) {
      this.toggle();

      this.trigger.addEventListener("click", (evt) => {
        evt.preventDefault();

        this.toggle();
      });

      document.addEventListener("focusin", () => {
        if (!this.contains(document.activeElement)) {
          if (this.state.status === "open") {
            this.trigger.focus();
          }

          this.toggle("closed");
        }
      });

      return;
    }

    this.innerHTML = this.initialMarkup;
  }

  toggle(forcedStatus) {
    if (forcedStatus) {
      if (this.state.status === forcedStatus) {
        return;
      }

      this.state.status = forcedStatus;
    } else {
      this.state.status = this.state.status === "closed" ? "open" : "closed";
    }
  }

  processStateChange() {
    this.root.setAttribute("status", this.state.status);
    this.root.setAttribute("enabled", this.state.enabled ? "true" : "false");

    this.manageFocus();

    switch (this.state.status) {
      case "closed":
        this.trigger.setAttribute("aria-expanded", "false");
        this.trigger.setAttribute("aria-label", "Open menu");
        break;
      case "open":
      case "initial":
        this.trigger.setAttribute("aria-expanded", "true");
        this.trigger.setAttribute("aria-label", "Close menu");
        break;
    }
  }

  manageFocus() {
    if (!this.state.enabled) {
      this.focusableElements.forEach((element) =>
        element.removeAttribute("tabindex")
      );
      return;
    }

    switch (this.state.status) {
      case "open":
        this.focusableElements.forEach((element) =>
          element.removeAttribute("tabindex")
        );
        break;
      case "closed":
        [...this.focusableElements]
          .filter(
            (element) =>
              element.getAttribute("data-element") !== "responsive-menu-trigger"
          )
          .forEach((element) => element.setAttribute("tabindex", "-1"));
        break;
    }
  }

  isColliding(leftEl, rightEl) {
    leftEl = leftEl.getBoundingClientRect();
    rightEl = rightEl.getBoundingClientRect();

    return leftEl.right >= rightEl.left;
  }
}

window.addEventListener("load", () => {
  if ("customElements" in window) {
    customElements.define("responsive-menu", ResponsiveMenu);
  }
});

export default ResponsiveMenu;
